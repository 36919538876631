<template>
  <v-card flat>
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <v-data-table
              :headers="headers"
              :items="updates"
              calculate-widths
              loading-text="Estamos buscando seus resultados"
              :loading="loading"
              hide-default-footer
          >
            <template v-slot:items="props">
              <td class="text-left">{{ props.item.version }}</td>
              <td class="text-left">{{ props.item.date }}</td>
              <td class="text-left">{{ props.item.text }}</td>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="confirmUpdate(item)"
              >send
              </v-icon
              >
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import UpdateService from "@/services/UpdateService";
export default {
  name: 'ListUpdates',
  data: function() {
    return {
      page: 1,
      pageCount: 0,
      loading: false,
      updates: [],
      total: 0,
      headers: [
        {
          text: "Versão",
          value: "version",
          align: "left"
        },
        {
          text: "Data",
          value: "date",
          align: "left"
        },{
          text: "Nota",
          value: "text",
          align: "left"
        },
        { text: "Ação", value: "action" }
      ]
    };
  },
  watch:{
    // page: async function(val) {
    //   const res = await new updateservice().getupdates(this.page)
    //   this.updates = res.data.data
    //   this.total = parseInt(res.data.total)
    // }
  },
  mounted() {
    this.getUpdates()
  },
  methods: {
    async getUpdates() {
      const res = await new UpdateService().getUpdates()
      this.updates =  res.data
      console.log(this.updates)
      this.total =  parseInt(res.data.length)
    },
    async confirmUpdate(version) {
      let res;
      await this.$confirm(
          "Deseja atualizar o servidor da versao "+localStorage.getItem("currentVersion")+" para a versão "+version.version+" ?",
          async () => {
            console.log("Atualizando ....")
            try {
              this.loading = true
              res = await new UpdateService().update(version)
            } finally {
              this.loading = false
            }
          }
      )
      if (res) this.$info.open(res.data)
    }
  }
};
</script>